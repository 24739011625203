html,body,#root{
  height:100%;
}
#root{
  height:100%;
  /* background:#dadada; */
}

#root > div{
  background:white;
  height:100%;
  max-width: 600px;
  margin: 0 auto;
}
.chatcont{
  max-width: 750px;
}
@media (min-width: 1000px) {

  #root > div {
    border-radius:20px;
    overflow:hidden;
    max-height: max(80vh, 600px);
    width: max(45vw, 900px);
    max-width: max(45vw, 900px);
    align-self:center;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
  }

  #root{
    background:#eaeaea;
    display:flex;
  }
  
  .chatcont{
    max-width: 950px;
  }
}
.single-button{
  margin:1px !important;
}
.MuiButton-root{
  line-height: 1.25 !important;
}

.MessageContent-other,.relative{
  position: relative !important;
}

.MessageContent-other::before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -10px;
  right: auto;
  top: 16px;
	bottom: auto;
	border: 10px solid;
	border-color: #eaeaea #eaeaea transparent transparent;
}


/* .MuiMessage-other>div, .MuiMessage-self>div{
  margin:0px !important;
}
.MuiMessage-other + .MuiMessage-other>div{
  margin-top: 0px !important;
} */
/* 
.inputsect{
  position:fixed;
  bottom:0px;
  width:97%;
} */

.inputsect{
  margin-top:10px  !important;
} 

.MuiTypography-root{
  white-space:inherit !important;
}

.chat-bubble {
  background-color:#E6F8F1;
  padding:16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display:inline-block;
  width:60px;
}
.typing {
  align-items: center;
  display: inline-flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6CAD96 ;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color:#6CAD96; 
  }
  28% {
    transform: translateY(-7px);
    background-color: #9ECAB9; 
  }
  44% {
    transform: translateY(0px);
    background-color: #B5D9CB; 
  }
}

.selectcontainer *{
  /* capital */
  text-transform: none  !important;
}

.ratingStar{
  width: 3em;
  height: 3em;
  background-color: #BBB;
  clip-path: polygon(51% 1%, 64% 37%, 100% 40%, 72% 63%, 83% 99%, 51% 77%, 18% 99%, 29% 63%, 1% 40%, 37% 37%);
  cursor: pointer;
}

.ratingStar.orange{
  background-color: #FEBE00 !important;
}

.MessageContent-other img{
  max-width: 100%;
  height: auto;
}